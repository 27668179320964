import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';

// SERVICES
import { JWTTokenService } from './jwt.token.service';
import { UrlResolverService } from './url-resolver.service';
import { SFLoggerService } from './sf-logger.service';

// MODELS
import { PhotoEstimateEligibility } from '../model/photo-estimate.model';
import { PhotoEstimateAssignment } from 'app/model/photo-estimate-assignment.model';

// ENUMS
import { SFLoggerFields } from 'app/enums/sf-logger-fields';
import { SFLoggerMessageIds } from 'app/enums/sf-logger-message-ids';

@Injectable({
  providedIn: 'root',
})
export class PhotoEstimateService {
  private photoEstimateUrl: string;
  private contactPreference: string;
  private photoEstimateError: string;

  constructor(private http: HttpClient,
    private urlResolverService: UrlResolverService,
    private jwtTokenService: JWTTokenService,
    private sfLoggerService: SFLoggerService,
  ) {
    this.photoEstimateUrl = this.urlResolverService.getServiceUrl('photoEstimate');
  }

  public sendPhotoEstimateAssignment(
    extClaimId: string,
    vehicleNum: string,
    extParticipantId: string,
    peAssignmentObj: PhotoEstimateAssignment
  ): Observable<number> {
    let url = `${this.photoEstimateUrl}/${extClaimId}/vehicles/${vehicleNum}/assignment`;

    if (extParticipantId) {
      url = `${url}?extParticipantId=${extParticipantId}`;
    }

    // SPARKL Logging
    let loggingDetails = [
      SFLoggerFields.ENDPOINT, url,
      SFLoggerFields.CALLED_SERVICE, SFLoggerFields.PHOTO_ESTIMATE_API,
      SFLoggerFields.HTTP_REQUEST, SFLoggerFields.PHOTO_ESTIMATE_ASSIGNMENT,
      SFLoggerFields.PE_REPLACE_EXISTING_ASSN, peAssignmentObj.replaceExistingAssignment,
      SFLoggerFields.PE_CONTACT_PREF_EMAIL, peAssignmentObj.contactPreference.email, // this passes a boolean value
      SFLoggerFields.PE_CONTACT_PREF_PHONE, peAssignmentObj.contactPreference.phone, // this passes a boolean value
    ];
    this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_DOWNSTREAM_INIT, "Calling PE API - POST /assignment", loggingDetails);

    const headers = this.setHttpHeaders();
    const httpOptions : Object = { headers: headers, observe: 'response' };

    return new Observable(observer => {
      this.http
        .post<any>(url, peAssignmentObj, httpOptions)
        .subscribe(
          response => {
            // SPARKL Logging
            loggingDetails.push(SFLoggerFields.HTTP_RESPONSE, response.status.toString())
            this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_DOWNSTREAM_SUCCESS, "SUCCESS - PE API - POST /assignment", loggingDetails)
            observer.next(response.status);
        },
        error => {
          if (error.status === 401) {
            error.statusText = 'Authentication Error Calling Photo Estimate API';
          } else if (error.status === 500) {
            error.statusText = 'Error Calling Photo Estimate API';
          } else if (error.status === 400) {
            error.statusText = 'Validation Error Calling Photo Estimate API';
          } else {
            error.statusText = 'Unknown Error calling Photo Estimate API';
          }

          // SPARKL Logging
          loggingDetails.push(
            SFLoggerFields.LOG_LEVEL, SFLoggerFields.ERROR,
            SFLoggerFields.ENDPOINT, error.url,
            SFLoggerFields.HTTP_RESPONSE, error.status.toString()
          )
          this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_DOWNSTREAM_ERROR, "ERROR - PE API - POST /assignment", loggingDetails);

          observer.error(error);
        }
      );
    });
  };

  public getEligibility(externalClaimId: string, vehicleNum: string, extParticipantId: string): Observable<PhotoEstimateEligibility> {
    let url = `${this.photoEstimateUrl}/${externalClaimId}/vehicles/${vehicleNum}/assignment/eligibility`;

    if (extParticipantId) {
      url = `${url}?extParticipantId=${extParticipantId}`;
    }

    // SPARKL Logging
    let loggingDetails = [
      SFLoggerFields.ENDPOINT, url,
      SFLoggerFields.CALLED_SERVICE, SFLoggerFields.PHOTO_ESTIMATE_API,
      SFLoggerFields.HTTP_REQUEST, SFLoggerFields.PHOTO_ESTIMATE_ELIGIBILITY,
    ];
    this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_DOWNSTREAM_INIT, "Calling PE API - GET /assignment/eligibility", loggingDetails);

    const headers = this.setHttpHeaders();
    const httpOptions = {headers: headers};

    return this.http.get<PhotoEstimateEligibility>(url, httpOptions).pipe(
      map(
        response => {
          // SPARKL Logging
          this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_DOWNSTREAM_SUCCESS, "SUCCESS - PE API - GET /assignment/eligibility", loggingDetails);
          return response
        }
      )
    ).pipe(
      catchError(
        (error: any, caught: Observable<any>) => {
          if (error.status === 401) {
            error.statusText = 'Authentication Error Calling Photo Estimate API';
          } else if (error.status === 500) {
            error.statusText = 'Error Calling Photo Estimate API';
          } else if (error.status === 400) {
            error.statusText = 'Validation Error Calling Photo Estimate API';
          } else {
            error.statusText = 'Unknown Error calling Photo Estimate API';
          }

          // SPARKL Logging
          loggingDetails.push(
            SFLoggerFields.LOG_LEVEL, SFLoggerFields.ERROR,
            SFLoggerFields.ENDPOINT, error.url,
            SFLoggerFields.HTTP_RESPONSE, error.status.toString()
          )
          this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_DOWNSTREAM_ERROR, "ERROR - PE API - GET /assignment/eligibility", loggingDetails);

          throw error;
        }
      ),
    );
  }

  setHttpHeaders() {
    const jwtToken = this.jwtTokenService.getOktaJWT();

    const headers = new HttpHeaders({
      'correlation-id': this.sfLoggerService.getCorrelationId(),
      'x-api-key': 'sf-photo-estimate-api-consumer-repair-assistant',
      ...((jwtToken && jwtToken != '') && {Authorization: jwtToken}),
    });

    return headers;
  }

  setContactPreference(contactPreference: string) {
    this.contactPreference = contactPreference;
  }

  getContactPreference(): string {
    return this.contactPreference;
  }

  setPhotoEstimateError(peError: string) {
    this.photoEstimateError = peError;
  }

  getPhotoEstimateError(): string {
    return this.photoEstimateError;
  }
}
