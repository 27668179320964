import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// COMPONENTS
import { SelectAShopComponent } from './select-a-shop/select-a-shop.component';
import { ShopSelectorComponent } from './shop-selector/shop-selector.component';
import { ConfirmationPageComponent } from './confirmation-page/confirmation-page.component';
import { NonProgramComponent } from './non-program/non-program.component';
import { RentalVehiclePreferenceComponent } from './rental-vehicle-preference/rental-vehicle-preference.component';
import { RentalConfirmationComponent } from './rental-confirmation/rental-confirmation.component';
import { EstimateOptionsComponent } from './estimate-options/estimate-options.component';
import { PhotoEstimateLandingComponent } from './photo-estimate-landing/photo-estimate-landing.component';
import { PhotoEstimateContactComponent } from './photo-estimate-contact/photo-estimate-contact.component';
import { PhotoEstimateConfirmationComponent } from './photo-estimate-confirmation/photo-estimate-confirmation.component';
import { PhotoEstimateErrorPageComponent } from './photo-estimate-error-page/photo-estimate-error-page.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { AuthErrorPageComponent } from './auth-error-page/auth-error-page.component';
import { LandingPageBComponent } from './landing-page-b/landing-page-b.component';
import { HelpCategoryComponent } from './help-category/help-category.component';
import { HelpFaqVideoComponent } from './help-faq-video/help-faq-video.component';
import { HelpSubcategoryComponent } from './help-subcategory/help-subcategory.component';
import { HelpFaqComponent } from './help-faq/help-faq.component';

// GUARDS
import { BookmarkRefreshGuard } from './guards/bookmark-refresh.guard';
import { JWTTokenGuard } from './guards/jwt-token.guard';
import { UrlValidationGuard } from './guards/url-validation.guard';

const routes: Routes = [
  { path: '', redirectTo: 'shopsearch', pathMatch: 'full' },
  {
    path: 'landing',
    component: LandingPageBComponent,
    canActivate: [JWTTokenGuard, UrlValidationGuard],
  },
  {
    path: 'category',
    component: HelpCategoryComponent,
    outlet: 'help',
    canActivate: [JWTTokenGuard, UrlValidationGuard],
  },
  {
    path: 'sub-category/:name',
    component: HelpSubcategoryComponent,
    outlet: 'help',
  },
  {
    path: 'faq/:majorGroup/:subGroup/:id/:analyticsId',
    component: HelpFaqComponent,
    outlet: 'help',
  },
  {
    path: 'faq-video/:majorGroup/:videoName',
    component: HelpFaqVideoComponent,
    outlet: 'help',
  },
  {
    path: 'shopsearch',
    component: SelectAShopComponent,
    canActivate: [JWTTokenGuard, UrlValidationGuard],
  },
  {
    path: 'estimateoptions',
    component: EstimateOptionsComponent,
    canActivate: [JWTTokenGuard, BookmarkRefreshGuard],
  },
  {
    path: 'repairshops',
    component: ShopSelectorComponent,
    canActivate: [JWTTokenGuard],
  },
  {
    path: 'confirm/claim/:claim/role/:role/client/:client',
    component: ConfirmationPageComponent,
    canActivate: [JWTTokenGuard],
  },
  {
    path: 'nonprogram',
    component: NonProgramComponent,
    canActivate: [JWTTokenGuard],
  },
  {
    path: 'rental',
    component: RentalVehiclePreferenceComponent,
    canActivate: [JWTTokenGuard],
  },
  {
    path: 'rentalconfirm',
    component: RentalConfirmationComponent,
    canActivate: [JWTTokenGuard],
  },
  {
    path: 'errorpage',
    component: ErrorPageComponent,
  },
  {
    path: 'photoestimate/claim/:claim/role/:role/client/:client',
    component: PhotoEstimateLandingComponent,
    canActivate: [JWTTokenGuard, UrlValidationGuard]
  },
  {
    path: 'pecontact/claim/:claim/role/:role/client/:client',
    component: PhotoEstimateContactComponent,
    canActivate: [JWTTokenGuard]
  },
  {
    path: 'peconfirm',
    component: PhotoEstimateConfirmationComponent,
    canActivate: [JWTTokenGuard]
  },
  {
    path: 'peerrorpage',
    component: PhotoEstimateErrorPageComponent,
    canActivate: [JWTTokenGuard]
  },
  {
    path: 'autherrorpage',
    component: AuthErrorPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
