import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ChangeDetectorRef
} from '@angular/core';
import { FooterDisplayService } from './service/footer-display.service';
import { environment } from 'environments/environment';
import { ModalComponent } from './modal/modal.component';
import { WindowReference } from './model/window-reference.model';
import { ServiceClientsService } from './service/service-clients.service';
import { FeatureFlags } from './model/feature-flags.model';
import { Subscription } from 'rxjs/Subscription';
import { Maintenance } from './model/maintenance.model';
import { RepairResponse } from 'app/model/repair-response.model';
import { ReplaySubject, Observable } from 'rxjs';
import { UrlResolverService } from './service/url-resolver.service';
import { UserIdleService } from 'angular-user-idle';
import { JWTTokenService } from './service/jwt.token.service';
import { DataAnalyticsService } from './service/data-analytics.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('sessionExpired', { static: true }) sessionExpiredModal: ModalComponent;

  title = 'app';
  shopName: string;

  dataString: string;
  radiusString: string;
  headerInfo: string;
  loggedInStatus: boolean;
  routerSubscription: Subscription;
  featureFlagsSubscription: Subscription;
  urlResolverSubscription: Subscription;
  repairDetailsSubscription: Subscription;
  chatButtonScriptSrc: string;
  maintenance$: ReplaySubject<Maintenance>;
  featureFlags$: Observable<FeatureFlags>;
  claimNumber$: Observable<string>;
  repairDetails$: ReplaySubject<RepairResponse>;
  claimNum: string;
  isHidden = true;
  isLimited: boolean;
  isHeaderLimited: boolean;
  isChatChecked: boolean;
  // Session Management
  VIEW_COUNT = 120;
  countdown = 120;
  modalCountdown: number;
  count: number;
  counter = false;
  activeStatus = false;

  constructor(
    private footerDisplayService: FooterDisplayService,
    private urlResolverService: UrlResolverService,
    private windowRef: WindowReference,
    private clientService: ServiceClientsService,
    private _router: Router,
    private jwtTokenService: JWTTokenService,
    public changeDetectorRef: ChangeDetectorRef,
    public userIdle: UserIdleService,
    private dataAnalyticsService: DataAnalyticsService
    )
    { }

  ngOnInit(): void {
    this.changeComponentDisplayOnRouterChange();
    this._router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this._router.events.subscribe(evt => {
      if (evt instanceof NavigationEnd) {
        this._router.navigated = false;
        this.windowRef.nativeWindow.scrollTo(0, 0);
      }
    });

    this.chatButtonScriptSrc = environment.chatButtonScriptSrc;
    this.featureFlags$ = this.clientService.callToFetaureFlagServiceWithErrorDefault();
    this.maintenance$ = this.clientService.callToMaintenanceService();
    this.claimNumber$ = this.clientService.getClaimNumber();
    this.repairDetails$ = this.clientService.getRepairDetailsWithErrorDefault();

    // if transactional user, start session management and set footer to limited
    if (window.location.search.includes('claim=') || window.location.pathname.includes('claim/')) {
      this.isLimited = true;
      this.userIdle.onIdleStatusChanged().subscribe((isIdle) => {
        if (!isIdle) {
          this.activeStatus = true;
        }
      });
      this.watchForCustomerInactivity(); // Start watching for user inactivity
      this.displayKeepAliveModal(); // Start countdown and display keepAlive modal when user idle time is over
      this.startIdleCountdown(); // Take the customer to claims hub if they don't reply to keepAlive modal
      this.userIdle.ping$.subscribe(() => {
        if (this.activeStatus) {
          this.jwtTokenService.refreshJWTtoken();
        }
      });
    } else {
      this.isLimited = false;
    }

    this.prepareHerculesChat();
  }

  // Header & Footer

  showFooter() {
    this.footerDisplayService.changeFooterDisplay(true);
  }

  changeComponentDisplayOnRouterChange() {
    this.routerSubscription = this._router.events.subscribe(eventValue => {
      if (eventValue instanceof NavigationStart) {
        const navigationStartValue = <NavigationStart>eventValue;
        const repairShopsSearchRegEx = /.*\/repairshops.*/;
        const estimateAssistSearchRegex = /.*\/estimateoptions.*/;
        if (repairShopsSearchRegEx.test(navigationStartValue.url)) {
          this.isHeaderLimited = true;
          this.footerDisplayService.changeFooterDisplay(false);
        } else if (estimateAssistSearchRegex.test(navigationStartValue.url)) { //estimate assist flow
          this.isHeaderLimited = true;
          this.footerDisplayService.changeFooterDisplay(true);
        } else {
          this.isHeaderLimited = false;
          this.footerDisplayService.changeFooterDisplay(true);
        }
      }
    });
  }

  // Session Management
  watchForCustomerInactivity() {
    // Start watching for user inactivity.
    this.userIdle.startWatching();
    this.sessionExpiredModal.hide();
  }

  displayKeepAliveModal() {
    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe((count) => {
      this.sessionExpiredModal.show();
      this.count = (this.VIEW_COUNT) - count;
      this.changeDetectorRef.markForCheck();
    });
  }

  startIdleCountdown() {
    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => {
      this.endSession();
    });
  }

  endSession() {
    this.jwtTokenService.deleteJWTToken();
    this.windowRef.navigateTo(this.urlResolverService.buildHubRedirectUrl());
  }

  continueSession() {
    this.jwtTokenService.refreshJWTtoken();
    this.userIdle.resetTimer();
    this.sessionExpiredModal.hide();
  }

  prepareHerculesChat() {
      this.featureFlagsSubscription = this.featureFlags$.subscribe(flags => {
        let enableChatGlobal = flags.click_to_chat_enabled;
        let enableChatConfirmation = flags.click_to_chat_confirmation;
        console.log(`Chatbot flags - enableChatGlobal: ${enableChatGlobal}, enableChatConfirmation: ${enableChatConfirmation}, scriptSrc: ${this.chatButtonScriptSrc}`);

        if (this.chatButtonScriptSrc && enableChatGlobal === true && enableChatConfirmation === true) {
          this.urlResolverSubscription = this.urlResolverService.getUrlParamsObservable().subscribe(urlParams => {
            let externalClaimId = urlParams.externalClaimId;
            let externalClientId = urlParams.externalClientId;

            if (externalClaimId) {
              this.repairDetailsSubscription = this.repairDetails$.subscribe(repairDetails => {
                  this.loadHerculesChat(externalClaimId, externalClientId, repairDetails.ClaimNumber);
                },
              );
            } else {
              this.loadHerculesChat(externalClaimId, externalClientId, undefined);
            }
          });
        }
      });
  }

  loadHerculesChat(externalClaimId, externalClientId, claimNumber) {
    console.log('Starting Chatbot...');

    if (!claimNumber) {
      claimNumber = '';
    }

    let claimNumMasked = claimNumber.substring(0, 2);
    console.log(`externalClaimId: ${externalClaimId}, externalClientId: ${externalClientId}, claimNum: ${claimNumMasked}*******`);

    const loa4Token = this.getCookie('sf-cauth1') ? this.getCookie('sf-cauth1') : this.getCookie('sf-icp-sso');

    const isUnauthenticated = loa4Token && loa4Token.trim() !== ''? false : true;
    let chatButtonScriptElement = document.createElement('script');

    chatButtonScriptElement.setAttribute("id", 'chatButton');
    chatButtonScriptElement.setAttribute("type", 'module');
    chatButtonScriptElement.setAttribute("src", this.chatButtonScriptSrc);
    chatButtonScriptElement.setAttribute('data-configKey', 'DigitalExperienceChatbot');
    chatButtonScriptElement.setAttribute('data-customerFacing', 'true');
    chatButtonScriptElement.setAttribute('data-unauthenticatedUser', `${isUnauthenticated}`);
    chatButtonScriptElement.setAttribute('crossorigin', 'anonymous');
    chatButtonScriptElement.setAttribute('data-customerName', 'Neighbor');
    chatButtonScriptElement.setAttribute(
      'data-additionalAttributes',
      JSON.stringify({
        claimNumber,
        contactStartPage: window.location.href,
        experience: 'claims-rfr',
        chatbotParameters: JSON.stringify({
          claimNumber,
          externalClaimId,
          externalClientId
        }),
      })
    );

    document.head.appendChild(chatButtonScriptElement);

  }

  getCookie(cookieName) {
    let name = cookieName + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');

    for (let i = 0; i <ca.length; i++) {
      let c = ca[i];

      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }

      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }

    return "";
  }

  closeHelpDrawer(){
    this.dataAnalyticsService.sendPageData('landing-page');
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();

    if (this.featureFlagsSubscription) {
      this.featureFlagsSubscription.unsubscribe();
    }

    if (this.urlResolverSubscription) {
      this.urlResolverSubscription.unsubscribe();
    }

    if (this.repairDetailsSubscription) {
      this.repairDetailsSubscription.unsubscribe();
    }
  }
}
