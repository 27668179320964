import { Component, AfterViewInit, OnDestroy, Inject } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { WINDOW } from 'app/service/window.service';

@Component({
  selector: 'app-one-x-header-full',
  templateUrl: './one-x-header-full.component.html',
  styleUrls: ['./one-x-header-full.component.css']
})
export class OneXHeaderFullComponent implements AfterViewInit, OnDestroy {

  dataType = '1x-header-unauth';
  dataLoginDestinationUrl: string;

  constructor(
    private cookieService: CookieService,
    @Inject(WINDOW) private window: Window,
  ) {
    this.checkCookieAuth();
  }

  checkCookieAuth() {
    this.dataLoginDestinationUrl = encodeURIComponent(window.location.href);
    if (this.cookieService.get('sf-cauth1')) {
      this.dataType = '1x-header-auth';
    } else if (this.cookieService.get('sf-cauth-lite')) {
      this.dataType = '1x-header-unauth';
    }
  }

  ngAfterViewInit() {
    this.window['oneX'].addElement(document.querySelector('#headerComponentContainerFull'));
  }

  ngOnDestroy() {
    this.window['oneX'].removeElement(document.querySelector('#headerComponentContainerFull'));
  }
}
